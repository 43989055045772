<template lang="pug">
NuxtLayout(:name="$isSol ? 'charter-page' : 'banner-default'")
  .col-12.mt-4.mb-5.mt-md-5.pb-5.pt-2
    .h2(data-i18n="solresor-app-webpage-title") {{ $t('solresor-app-webpage-title') }}
    p.my-3(data-i18n="solresor-app-webpage-text") {{ $t('solresor-app-webpage-text') }}
    a.badge(:href="appStoreAppUri" target="_blank" rel="noopener noreferrer")
      img(src="/logos/apple.webp" alt="Download on the App Store")
    a.badge(:href="playAppUri" target="_blank" rel="noopener noreferrer")
      img(src="/logos/google.webp" alt="Get it on Google Play")

  .full-width.bg-light-turquoise(v-if="$isSol")
    .container
      .pb-5.mb-5.charter-start
        .py-4.py-md-5.mb-5
          .container-fluid.layout-container.pb-4.pt-3.pt-md-0
            HydrateWhenVisible.min-h-px-600.min-h-px-md-450
              LazyCharterDestinations

  div(v-else)
    LazyHomeTripList
</template>

<script setup>
const { t } = useI18n()
const rootStore = useRootStore()
const charterPackagesStore = useCharterPackagesStore()

const tripStore = useTripStore()

const {
  appMeta,
  redirectToAppStore,
  playAppUri,
  appStoreAppUri,
} = useApp()

rootStore.SET_PAGE_BANNER({
  title: appMeta.value.title,
  type: 'small',
})

const { $isSol } = useNuxtApp()

await useAsyncData(async () => {
  if ($isSol) {
    await charterPackagesStore.getDestinations()
  } else {
    await tripStore.fetchCategoryStart()
  }

  return {}
})

useHead({
  title: () =>  appMeta.value.title,
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: () => t('solresor-app-webpage-title'),
    },
  ],
})

onMounted(() => {
  redirectToAppStore()
})
</script>

<style lang="scss" scoped>
.badge {
  img {
    width: 100%;
    max-width: 150px;
  }
}
</style>